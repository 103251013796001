import React from 'react';
import '../../pages/page-styles.scss';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const MultiTiered = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAbout(title: { eq: "Introduction" }) {
        contentTwo {
          raw
        }
        image {
          file {
            url
          }
        }
      }
    }
  `);

  const { contentfulAbout } = data;
  const contentTwo = JSON.parse(contentfulAbout.contentTwo.raw);
  const pic = contentfulAbout.image.file.url;

  return (
    <section className="section pt-0">
      <div className="content">
        <div className="container">
          <div>
            <h3 className="is-size-3">
              Multi-Tiered System of Supports (MTSS)
            </h3>
            <figure className="image">
              <img alt="tiers" src={pic} className="tiers" />
            </figure>
            {renderRichText(contentTwo)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MultiTiered;
