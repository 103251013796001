import React from 'react';
import './style.scss';
import Advisory from './Advisory';
import Team from './Team';
import Faq from './Faq';

const Body = () => {
  return (
    <>
      <Team />
      <Advisory />
      <Faq />
    </>
  );
};

export default Body;
