import React from 'react';
import MultiTiered from './MultiTiered';
import Intro from './Intro';
import Mission from './Mission';
import Overview from './Overview';
import './style.scss';

const Hero = () => {
  return (
    <div className="has-background-info">
      <Intro />
      <MultiTiered />
      <Mission />
      <Overview />
    </div>
  );
};

export default Hero;
