import React from 'react';
import Columns from './Columns';
import createBioObjs from '../../utils/createBioObjects';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import './style.scss';
import '../../pages/page-styles.scss';

const columnsClass = 'columns is-mobile is-multiline';
const columnClass = 'column is-full-mobile is-4-desktop is-4-tablet';

const Team = () => {
  const data = useStaticQuery(graphql`
    {
      aboutContent: contentfulAbout(title: { eq: "Leadership" }) {
        content {
          raw
        }
        title
      }
      bios: allContentfulLeadershipBio(sort: { fields: sortId }) {
        nodes {
          email
          phone
          name
          titles {
            raw
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const { aboutContent, bios } = data;

  const content = JSON.parse(aboutContent.content.raw);
  const title = aboutContent.title;

  const biosDisplay = bios.nodes.map(node => {
    const inBio = createBioObjs(node);
    inBio.clickable = true;
    return inBio;
  });

  return (
    <section className="section anchor" id="Leadership">
      <div className="container">
        <div>
          <div className={columnsClass}>
            <div className="column">
              <h1 className="is-size-1 title mb-1">{title}</h1>
              <h3 className="is-size-3 title mb-1">
                The Development and Evaluation Team
              </h3>
              {renderRichText(content)}
            </div>
          </div>
          <div className={`${columnsClass} pb-4`}>
            <Columns bios={biosDisplay} columnClass={columnClass} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
