const createBioObjs = bio => {
  const { name, email, phone, titles, image } = bio;
  const header = titles ? JSON.parse(titles.raw) : null;

  const bioObj = {
    name,
    header,
    contact: { phone, email },
    image: image.file.url,
  };
  return bioObj;
};

export default createBioObjs;
