import React from 'react';
import '../../pages/page-styles.scss';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Overview = () => {
  const data = useStaticQuery(graphql`
    {
      format: contentfulAbout(title: { eq: "Format" }) {
        title
        content {
          raw
        }
      }
      research: contentfulAbout(title: { eq: "Research" }) {
        title
        content {
          raw
        }
        image {
          file {
            url
          }
        }
        contentTwo {
          raw
        }
      }
    }
  `);

  const { format, research } = data;

  const formatContent = JSON.parse(format.content.raw);
  const formatTitle = format.title;
  const researchContent = JSON.parse(research.content.raw);
  const researchContentTwo = JSON.parse(research.contentTwo.raw);
  const researchTitle = research.title;
  const researchImg = research.image.file.url;

  return (
    <section className="section">
      <div className="content">
        <div className="container">
          <div>
            <div id="Format" className="anchor-intro">
              <h1 className="title is-size-1">{formatTitle}</h1>
              {renderRichText(formatContent)}
            </div>
            <div id="Research" className="anchor">
              <div className="pt-6 pb-6">
                <h1 className="title is-size-1">{researchTitle}</h1>
                {renderRichText(researchContent)}
              </div>
              <img src={researchImg} alt="key findings" className="mb-4" />
              {renderRichText(researchContentTwo)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Overview;
