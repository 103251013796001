import React from 'react';
import Card from './Card';

const Columns = ({ bios, columnClass }) => {
  return bios.map((bio, i) => {
    return (
      <div className={columnClass} key={bio.name}>
        <Card bio={bio} idx={i} />
      </div>
    );
  });
};

export default Columns;
