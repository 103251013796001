import React from 'react';
import './style.scss';

const SingleFaq = ({ header, content }) => {
  return (
    <div className="py-3">
      {header && (
        <h5 className="has-text-weight-semibold diamond">
          <span className="is-size-5 pl-3">{header}</span>
        </h5>
      )}
      {content}
    </div>
  );
};

export default SingleFaq;
