import React from 'react';
import '../../pages/page-styles.scss';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Intro = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAbout(title: { eq: "Introduction" }) {
        title
        content {
          raw
        }
        contentTwo {
          raw
        }
      }
    }
  `);

  const { contentfulAbout } = data;
  const content = JSON.parse(contentfulAbout.content.raw);

  return (
    <section className="section">
      <div className="container">
        <div id="Introduction" className="anchor-intro">
          <div className="content">
            <div>{renderRichText(content)}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
