import React from 'react';
import renderRichText from '../utils/renderRichText';
import { defaultContact } from '../utils/contact';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Bio = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulLeadershipBio(sort: { fields: sortId }) {
        nodes {
          bioBody {
            raw
          }
          email
          phone
          name
          titles {
            raw
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  `);
  const { allContentfulLeadershipBio } = data;
  const createBioObjs = node => {
    const { name, bioBody, email, phone, titles, image } = node;

    const body = bioBody ? JSON.parse(bioBody.raw) : null;
    const header = JSON.parse(titles.raw);
    const img = image.file.url;

    const bioObj = {
      name,
      body,
      header,
      image: img,
      contact: {
        phone,
        email,
      },
    };
    return bioObj;
  };

  const biosDisplay = allContentfulLeadershipBio.nodes.map(node =>
    createBioObjs(node)
  );

  const pathIdx = location.pathname.split('/')[2];

  const pageBio = biosDisplay[pathIdx];

  const { name, body, header, image, contact } = pageBio;

  const columnTwo = 'column is-1';

  return (
    <section className="section">
      <div className="columns is-vcentered">
        <div className={columnTwo} />
        <div className="column is-3 mr-6">
          <img src={image} className="image" alt={name} />
        </div>
        <div className="column">
          <p className="title is-1 mb-1">{name}</p>
          {renderRichText(header).map((headers, i) => (
            <p className="is-size-5" key={i}>
              {headers}
            </p>
          ))}
        </div>
        <div className={columnTwo} />
      </div>
      <div className="columns">
        <div className={columnTwo} />
        <div className="column">
          {body &&
            renderRichText(body).map((item, i) => (
              <p key={i} className="mt-4 is-size-6">
                {item}
              </p>
            ))}
        </div>
        <div className={columnTwo} />
      </div>
      <div className="columns">
        <div className={columnTwo} />
        {contact && (
          <div className="column">
            <p className="mt-2">Contact Info</p>
            {contact.phone && (
              <div className="pt-4">
                <span className="icon-text">
                  <span className="icon">
                    <i className={`fas fa-phone`}></i>
                  </span>
                  <span>{contact.phone}</span>
                </span>
              </div>
            )}
            <div>
              <span className="icon-text">
                <span className="icon">
                  <i className={`fas fa-envelope`}></i>
                </span>
                <a
                  href={`mailto:${contact.email || defaultContact.email}`}
                  target="_blank"
                  rel="noreferrer"
                  className="footer-text"
                >
                  {contact.email || defaultContact.email}
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Bio;
