import React from 'react';
import { Router } from '@reach/router';
import AboutComponent from '../components/about/';
import Layout from '../components/layout';
import Bio from '../templates/bio';

const About = ({ location }) => {
  const headerText =
    location.pathname === '/about/' || '/about' ? 'ABOUT iDECIDE' : null;

  return (
    <Layout headerText={headerText} heroFont="has-text-white" heroBg="fields">
      <Router>
        <AboutComponent path="/about" />
        <Bio path="/about/:idx" />
      </Router>
    </Layout>
  );
};

export default About;
