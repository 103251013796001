import React from 'react';
import '../../pages/page-styles.scss';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Mission = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAbout(title: { eq: "Mission" }) {
        title
        content {
          raw
        }
      }
    }
  `);

  const { contentfulAbout } = data;
  const content = JSON.parse(contentfulAbout.content.raw);
  const title = contentfulAbout.title;

  return (
    <section className="section mountains-bg anchor" id="Mission">
      <div className="container">
        <div className="hero">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column">
              <h1 className="hero-title title is-size-1">{title}</h1>
              <div className="content">{renderRichText(content)}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
