import React from 'react';
import SingleFaq from './SingleFaq';
import Email from '../Email';

import '../../pages/page-styles.scss';

const Faq = () => {
  const faqItems = [
    {
      header: 'What is iDECIDE?',
      content: (
        <p>
          iDECIDE (<strong>D</strong>rug <strong>E</strong>ducation
          <strong> C</strong>urriculum: <strong> I</strong>ntervention,
          <strong> D</strong>iversion, and <strong> E</strong>mpowerment), is a
          drug education curriculum developed to provide behavioral support and
          psychoeducation for middle and high school students. The program is
          designed to serve as a secondary prevention effort for youth at risk
          for escalation to problematic substance use and as an alternative to
          suspension, expulsion, and other exclusionary practices for
          school-based substance use infractions. iDECIDE provides schools and
          communities the resources to empower students to engage in healthy
          decision making. iDECIDE is not a cessation program, and is not meant
          to be used in lieu of treatment.
        </p>
      ),
    },
    {
      header: 'Why did we develop this program? ',
      content: (
        <p>
          There is an urgent need to define reasonable and effective school
          responses to student substance use infractions. To work towards this
          goal, it is essential that responses focus on the mental health and
          wellbeing of students and not on punishing behavior. Additionally,
          traditional exclusionary practices disproportionately impact
          low-income and black students. The iDECIDE curriculum provides an
          equitable response to infractions and promotes the future success of
          all students.
        </p>
      ),
    },
    {
      header: 'Who developed this program?',
      content: (
        <p>
          This program was developed by the Center for Addiction Medicine at
          Massachusetts General Hospital (MGH) in collaboration with the
          Massachusetts Department of Public Health (DPH) and the Institute for
          Health and Recovery (IHR). Student and school stakeholder feedback was
          gathered at multiple points during development.
        </p>
      ),
    },
    {
      header: 'Who was this program developeded for?',
      content: (
        <p>
          iDECIDE was developed for middle and high school students who use
          substances. The content is not specific to a single substance since
          some students may use multiple substances and using one substance
          increases the risk of using additional substances.
        </p>
      ),
    },
    {
      header: 'Is iDECIDE evidence-based?',
      content: (
        <p>
          The curriculum was developed with close attention to best practices
          and evidence- based practices in the scientific literature. Schools
          across Massachusetts are participating in an evaluation to measure the
          effectiveness of iDECIDE. Outcomes of this evaluation will be made
          available on our website and the program will be updated as guided by
          the data.
        </p>
      ),
    },
    {
      header: 'Is iDECIDE relevant for students using all forms of substances?',
      content: (
        <p>
          Yes, iDECIDE is agnostic to the specific substance(s) used by the
          participant.
        </p>
      ),
    },
    {
      header: 'What is the structure of the iDECIDE program?',
      content: (
        <p>
          iDECIDE is a 4-session (approx. 1 hour each) program that includes
          videos, discussion, activities, and on-your-own assignments. The
          sessions are structured to be conducted in either a group or
          individual format.
        </p>
      ),
    },
    {
      header: 'Is the timing of the program sessions flexible?',
      content: (
        <p>
          We recommend that the program be administered in 4 sessions. Ideally,
          modules will be delivered once per week for approximately 4 weeks. It
          is important that students are enrolled in the first session as
          proximal to the point of infraction as possible. However, if this does
          not work for your school, please speak with your iDECIDE coach about
          ways to modify the curriculum so that it fits the needs of your
          school.
        </p>
      ),
    },
    {
      header: 'How many times can a student be enrolled in iDECIDE?  ',
      content: (
        <p>
          This program may be appropriate for a student to re-enroll in.
          However, we recommend trying a different format to better meet the
          student’s needs. For example, if the student was first enrolled in a
          group, they may benefit from more individualized attention the second
          time around. Remember that this is not intended to be a tier 3
          cessation program and thus additional referrals may be necessary if a
          student is not responding to the iDECIDE curriculum.
        </p>
      ),
    },
    {
      header: 'What languages will the program be available in?',
      content: (
        <p>
          Currently, the program is available in English, Spanish, and
          Portuguese. If you have additional specific language requests, please
          let us know so we can prioritize it.
        </p>
      ),
    },
    {
      header: 'When will this program be available for us to implement?',
      content: (
        <p>
          Please contact the iDECIDE team to determine when this program will be
          available for your community at <Email />.
        </p>
      ),
    },
    {
      header:
        'What are the resources required to be able to implement iDECIDE in our school or community?',
      content: (
        <p>
          iDECIDE was developed with sustainability and scalability in mind to
          be able to meet the needs of a diverse range of schools and
          communities. Schools designate someone to be a trained facilitator and
          once they complete the free training, facilitators will have access to
          all the resources.
        </p>
      ),
    },
    {
      header: 'Do I need to be a clinician to be an iDECIDE facilitator?',
      content: (
        <p>
          No. The iDECIDE Facilitator Training Program was developed taking all
          backgrounds into consideration.
        </p>
      ),
    },
    {
      header: 'How will facilitators be trained?',
      content: (
        <p>
          Facilitators can volunteer to sign up or be assigned by their school
          or community program. The training is one-day, in-person and each site
          also receives support from an assigned iDECIDE to help with
          implementation. If you or someone you know might be interested in
          signing up for facilitator training, please contact us at <Email />.
        </p>
      ),
    },
    {
      header:
        'Is there a cost for training to be an iDECIDE facilitator or for bringing iDECIDE to our school.',
      content: (
        <p>
          No. iDECIDE is funded by the Massachusetts Department of Public
          Health. There is no cost for training or bringing the iDECIDE program
          to your school.
        </p>
      ),
    },
    {
      header:
        'Will facilitators be able to access the curriculum materials before the training?',
      content: (
        <p>
          No. Facilitators will receive a copy of the facilitator manual and
          participant workbook during their initial training. During the
          training they will also receive access and training on our learning
          management system that will be used to enroll and deliver the program
          to students. If your team would like a preview of these materials,
          please reach out to the iDECIDE team and we can set up a zoom viewing
          session.
        </p>
      ),
    },
    {
      header:
        'Do you have any other resources that we can access before we’re phased in?',
      content: (
        <p>
          Yes! To find additional resources you can check out our iDECIDE
          website:{' '}
          <a href="https://www.idecidemyfuture.org/resources">
            https://www.idecidemyfuture.org/resources
          </a>{' '}
          or find other active studies at the Center for Addiction Medicine:{' '}
          <a href="http://www.mghaddictionmedicine.com/research/active-studies-3">
            http://www.mghaddictionmedicine.com/research/active-studies-3
          </a>{' '}
          . If you are looking for more specific resources free to reach out to
          your iDECIDE coach, even if it isn’t iDECIDE related, they can assist
          with finding other resources.
        </p>
      ),
    },
    {
      header: 'What trainings will be offered outside of Massachusetts?',
      content: (
        <p>
          At this time, we do not have any trainings scheduled outside of MA.
          However, please contact us at <Email /> to inquire further about
          future virtual trainings.
        </p>
      ),
    },
    {
      header:
        'Will the School-Wide Assessment take place if the school has not yet been trained in iDECIDE?',
      content: (
        <p>
          Yes! The school-wide assessment would take place in the fall of each
          year, regardless of when a school is trained in the curriculum.
        </p>
      ),
    },
    {
      header:
        'Is the website and learning management system disability accessible?',
      content: (
        <p>
          iDECIDE is committed to making our resources available to everyone.
          Content was developed based on WCAG and ARIA specifications. If you
          have any questions about our accessibility features or if you are in
          any need of assistance, please email us at <Email />, or call us at
          617-643-1771.
        </p>
      ),
    },
    {
      header: 'Who should we contact if we have further questions?',
      content: (
        <p>
          If you have any further questions, please don’t hesitate to reach out
          to <Email />.
        </p>
      ),
    },
    {
      header: 'Who is Funding the Development and Evaluation of iDECIDE?',
      content: (
        <p>
          iDECIDE is funded by a Cooperative Agreement to MGH from the
          Massachusetts Department of Public Health, Office of Youth & Young
          Adult Services’ federal award by the Substance Abuse and Mental Health
          Services Administration.
        </p>
      ),
    },
    {
      content: (
        <p>
          <strong>
            If you would like updates on the iDECIDE program, please email us at{' '}
            <Email />
          </strong>{' '}
          or follow us on social media on Twitter @iDECIDEteam or on Instagram
          @project_idecide.
        </p>
      ),
    },
  ];

  return (
    <section className="section has-background-info anchor" id="FAQ">
      <div className="content">
        <div className="container mt-6">
          <div>
            <h3 className="is-size-3 mb-2">iDECIDE FAQs</h3>
            {faqItems.map((item, i) => {
              const { header, content } = item;
              return <SingleFaq header={header} content={content} key={i} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
