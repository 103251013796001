import React from 'react';
import ColumnHeader from './ColumnHeader';
import Columns from './Columns';
import { useStaticQuery, graphql } from 'gatsby';
import createBioObjs from '../../utils/createBioObjects';
import './style.scss';
import '../../pages/page-styles.scss';

const columnsClass = 'columns is-mobile is-multiline';
const smallColumnClass =
  'column is-full-mobile is-one-third-tablet is-4-desktop';

const Advisory = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulAdvisoryBoardBio(sort: { fields: sortId }) {
        nodes {
          name
          titles {
            raw
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const { allContentfulAdvisoryBoardBio } = data;

  const advisoryBios = allContentfulAdvisoryBoardBio.nodes.map(node => {
    const inBio = createBioObjs(node);
    inBio.advisory = true;
    return inBio;
  });

  return (
    <section className="section pt-2 has-background-info">
      <div className="container">
        <div>
          <ColumnHeader title={'The Advisory Board'} />
          <div className={columnsClass}>
            <Columns bios={advisoryBios} columnClass={smallColumnClass} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advisory;
