import React from 'react';
import Hero from './Hero';
import Body from './Body';

const AboutComponent = ({ content }) => {
  return (
    <>
      <Hero />
      <Body content={content} />
    </>
  );
};

export default AboutComponent;
