import React from 'react';

const columnsClass = 'columns is-multiline is-mobile mt-3';

const ColumnHeader = ({ title }) => {
  return (
    <div className={columnsClass}>
      <div className={`column`}>
        <h3 className="is-size-3 title ">{title}</h3>
      </div>
    </div>
  );
};

export default ColumnHeader;
