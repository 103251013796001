import React from 'react';
import { Link } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import './style.scss';

const Card = ({ bio, idx }) => {
  const { name: title, contact, header, image, clickable, advisory } = bio;
  const email = contact?.email || 'idecide@mgh.harvard.edu';

  if (advisory) {
    return (
      <div className="card card-bio has-background-info">
        <div className="card-image">
          <figure className="image is-3by4">
            <img src={image} className="bio-img" alt={title} />
          </figure>
        </div>
        <div className="card-content">
          <div className="media mb-0">
            <div>
              <p className="title is-5 mb-1">{title}</p>
            </div>
          </div>
          <hr className="my-2" style={{ backgroundColor: 'black' }} />
          <div className="pt-2 advisory-p">{renderRichText(header)}</div>
        </div>
      </div>
    );
  }

  return clickable ? (
    <Link to={`/about/${idx}`}>
      <div className="card card-bio has-background-info">
        <div className="card-image">
          <figure className="image is-3by4">
            <img src={image} className="bio-img" alt={title} />
          </figure>
        </div>
        <div className="card-content">
          <div className="media mb-0">
            <div>
              <p className="title is-5 mb-1">{title}</p>
              {/* <p className="is-6">{renderRichText(header)}</p> */}
            </div>
          </div>
          <hr className="my-2" style={{ backgroundColor: 'black' }} />
          <a
            href={`mailto:${email}`}
            target="_blank"
            rel="noreferrer"
            className="footer-text is-size-7"
          >
            {email}
          </a>
        </div>
      </div>
    </Link>
  ) : (
    <div className="card card-bio has-background-info">
      <div className="card-image">
        <figure className="image is-3by4">
          <img src={image} className="bio-img" alt={title} />
        </figure>
      </div>
      <div className="card-content">
        <div className="media mb-0">
          <div>
            <p className="title is-5 mb-1">{title}</p>
            <p className="is-6">{renderRichText(header)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
